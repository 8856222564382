body {
    font-family: "Lato", sans-serif;
  }
  
  .bg {
    background-color: rgb(255, 255, 255);
    padding: 0;
    /* position: fixed; */
    width: 100%;
    height: 100vh;
  }
  
  .sidenav {
    height: auto;
    position: fixed;
    z-index: 1;
    background-color: rgb(255, 255, 255);
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 20px;
    transition: 0.5s;
  }
  
  .navItem {
    
    text-decoration: none;
    color: #49256d;
    background-color: #ffffff;
    flex-direction: column;
  }
  
  .navItem:hover {
    color: #0b7b5d;
    background-color: #ffffff;
    text-decoration: none;
  }
  
  .main {
    transition: 0.5s;
    overflow-x: hidden;
    /* overflow-y: hidden; */
    padding-top: 52px;
   
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }
    .sidenav a {
      font-size: 18px;
    }
  }
  
  .topNavBar {
    height: 52px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    transition: 0.5s;
    top: 0;
    position: fixed;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.01);
  
    
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-bottom: 0.5px solid #d7d7d7;
  }
  
  .container {
    overflow-y: scroll;
  }
  
  .logoBox {
    display: flex;
    flex-direction: row;
    margin:0 75px 16px 30px;
  }
  
  .logo {
    width: 160px;
    height: 40px;
    border-radius: 8px;
    padding: 0;
    margin: 0;
  }
  
  .logoTitle {
    color: #000000;
    font-size: 20px;
    margin: 0 5px 0 8px;
    padding: 0;
  }
  
  .menuDrawer {
    width: 28px;
    height: 28px;
    margin-right: 16px;
    cursor: pointer;
  }
  
  .active {
    text-decoration: none;
    color:#0b7b5d;
    background-color: #f8f4f4;
    flex-direction: column;
    text-decoration: none;
  }
  
  .active:hover {
  
    color:  #0b7b5d;
    background-color: #f8f4f4;
    text-decoration: none;
  }
  
  .bellBox {
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  
  .bellIcon {
    right: 0;
  }
  