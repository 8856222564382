.container {
    height: 70vh;
    width: 100%;
  }
  .innerDiv {
    padding: 0px;
    width: 100%;
    min-width: 260px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }
  .validerror {
    color: red;
  }
  .form {
    margin-top: auto;
    margin: auto;
    padding-left: 10%;
    padding-right: 10%;
    width: 35%;
    min-width: 340px;
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: space-around;
  }
  .inputStyle {
    margin-bottom: 3px;
    margin-left: 10px;
    color: rgb(3, 3, 3);
    border: #efffff;
    width: 100%;
    height: 85%;
  }
  .inputStyle:focus {
    outline: none;
  }
  .heading {
    margin-top: 30px;
    font-size: 17px;
    font-weight: bold;
    justify-content: center;
    align-self: center;
    margin-bottom: 12px;
  }
  .link {
    font-weight: 300;
    text-decoration: none;
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .linebox {
    width: 50%;
    align-self: center;
    height: 1px;
    background-color: #bdbdbd;
    min-width: 260px;
    margin-top: 3px;
  }
  .loginBtn {
    height: 52px;
    padding: 9px 12px;
    border-radius: 2px;
    background: #141414;
    width: 105%;
    font-size: 17px;
    color: white;
    border-width: 0px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .loginBtn:focus {
    outline: none;
  }
  .input {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #7e7e7e;
    padding: 9px 12px;
    margin-top: 15px;
    width: 100%;
    margin-right: 20%;
    color: grey;
    border-radius: 2px;
  }
  .linkItem {
    color: white;
    font-weight: bold;
    text-decoration: none;
    margin: 1px;
    font-size: 16px;
  }
  .logo {
    box-shadow: 0 4px 8px 0 rgba(73, 69, 69, 0.39);
    margin-left: 1px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    font-size: 20px;
    background-color: rgb(234, 236, 238);
  }
  