.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .Homesectionone {
    width: 80%;
    height: 33%;
  }
  
  .linkItem {
    color: rgb(255, 255, 255);
    text-decoration: none;
    margin: 5px;
  }
  
  .blogleft {
    border-radius: 20px;
    height: 17.5cm;
    width: 300px;
    border-radius: 4px;
    font-family: Arial;
    color: rgb(0, 0, 0);
    font-size: 17px;
    background: white;
    text-decoration: none;
    border: 2px solid white;
    margin-top: 1px;
  }
  
  .Homesectionthree {
    margin-top: 10px;
    /* margin-left: 20px; */
    display: flex;
    flex-direction: row;
  }
  .courselist {
    background-color: aquamarine;
  }
  
  .buttonbox {
    margin-bottom: 30px;
    margin-left: 10px;
    color: rgb(51, 47, 47);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }
  
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(11, 12, 12, 0.5);
  }
  
  .buttontop {
    padding: 6px;
    height: 100%;
    width: 50%;
  }
  .container {
    margin: 10px;
    width: 100%;
  }
  .buttonbottom {
    margin-left: 14%;
    width: 60%;
    height: 100%;
  }
  .Homesectionone {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
  }
  .searchbar {
    margin-left: 15px;
    margin-top: 24px;
    width: 200%;
  }
  .searchbarbtn {
    margin-top: 25px;
  }
  .form {
    margin-top: 5px;
    margin: auto;
    padding-left: 10%;
    padding-right: 10%;
    border-radius: 12px;
    width: 50%;
    min-width: 360px;
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: center;
  }
  .inputStyle {
    margin: 3px;
    padding: 6px;
    border: 2px solid black;
    border-radius: 3px;
    box-sizing: border-box;
    width: 140%;
    height: 52px;
  }
  .submitBtn {
    margin-left: 373%;
    margin-top: 2px;
    height: 52px;
    padding: 7px 10px;
    border: 2px solid black;
    background: #000000;
    width: 100%;
    font-size: 17px;
    color: white;
  }
  
  .HomeLandingBox {
    grid-gap: 5px;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    margin-top: 1px;
  }
  .courselisttwo {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
  }
  .bloghead {
    margin-top: 5px;
    margin-left: 50px;
    font-weight: bold;
    font-size: 20px;
  }
  
  .Rbtn {
    border-radius: 5px;
    height: 0.8cm;
    width: 86%;
    font-family: Arial;
    color: #ffff;
    font-size: 13px;
    background: green;
    text-decoration: none;
    margin-top: 40px;
    border: 1px;
    cursor: pointer;
  }
  .Rbtn:hover {
    background-color: rgb(126, 122, 122);
  }
  
  .dropdown {
    margin: 12px 0 0px 0;
    box-shadow: 0 3px 3px 0 rgba(68, 68, 68, 0.119);
    margin-top: 28px;
    margin-left: 10px;
    height: 51px;
    width: 200px;
    background-color: white;
    text-decoration: none;
    border: 2px solid black;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
  }
  
  .gridContainer {
    max-width: 2000px;
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
  }
  
  .card {
    box-shadow: 0 4px 8px 0 rgba(66, 64, 64, 0.39);
    border-radius: 20px;
    transition: 0.3s;
    border-radius: 8px;
    display: flex;
    background-color: white;
    cursor: pointer;
    width: 520px;
    flex-direction: column;
  }
  @media (min-width: 400px) {
    .gridContainer {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  @media (min-width: 900px) {
    .gridContainer {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .Rbutton {
    height: 1.3cm;
    width: 205px;
    margin-left: 15px;
    font-family: Arial;
    color: #ffff;
    font-size: 13px;
    background: rgb(39, 40, 41);
    text-decoration: none;
    margin-top: 30px;
    border: 1px;
  }
  .Rbuttontn:hover {
    background-color: black;
  }
  
  .fullname {
    color: rgb(45, 45, 63);
    font-weight: bold;
  }
  .coursetype {
    color: rgb(187, 124, 7);
    font-weight: bold;
  }
  .total {
    margin-left: 20px;
  }
  